import ApiBase from '@/api/base'

class ApiBlog {
  static getAll (self) {
    self.http('get', `${ApiBase.baseApiUrl()}/posts`)
      .then(response => {
        ApiBase.setToStore(null, 'setPosts', response.data)
      })
  }
	static getPost (self, slug) {
		self.http('get', `${ApiBase.baseApiUrl()}/posts/${slug}`)
			.then(response => {
				ApiBase.setToStore(null, 'setPost', response.data)
			})
			.catch(() => self.isPageNotFound = true)
	}
}

export default ApiBlog
