import MainPost from '@/components/post/main-post/MainPost.vue'
import ApiBlog from '@/api/blog.js'

export default {
	name: 'PostPage',
	components: {
		MainPost
	},
	metaInfo () {
		return {
			title: this.metaTitle,
			meta: [{
				name: 'description',
				content: this.metaDescription,
			},
			{
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: this.metaTitle,
			},
			{
				name: 'og:description',
				content: this.metaDescription,
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: this.metaTitle,
			},
			{
				name: 'twitter:description',
				content: this.metaDescription,
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
		],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
	data: () => ({
		isPageNotFound: false,
	}),
	mounted () {
		ApiBlog.getPost(this, this.$route.params.slug)
	},
	computed: {
		metaTitle () {
			return this.state.post.title ? this.state.post.title : 'Post'
		},
		metaDescription () {
			if (!this.state.post.title) return
			return 'Topic description about "' + this.state.post.title + '" position'
		},
		postList () {
			if (!this.state.posts ||  !this.state.posts.length) return []
			return this.state.posts.filter(post => post.id !== this.state.post.id)
		}
	},
	watch: {
		isPageNotFound (value) {
			if (value) {
				this.$router.push({
					name: 'Page404'
				})
			}
		},
		'$route': function (newValue, oldValue) {
			if (oldValue.name === newValue.name) {
				ApiBlog.getPost(this, newValue.params.slug)
			}
	}
	}
}
